import React from 'react';
import {Container, Image, ImageContainer, Price, PriceContaienr, Title} from './styles';
import {UsuallyButton} from '../../../../components';
import {assets} from '../../../../assets';
import {ReactComponent as EnegrgySVG} from '../../../../assets/icons/energy.svg';

type TProps = {
  onPress: () => void;
  title: string;
  price: string;
};

const ResultContent: React.FC<TProps> = ({onPress, title, price}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <PriceContaienr>
        <EnegrgySVG />
        {/* <ImageContainer>
          <Image src={assets.coin} alt="coin" />
        </ImageContainer> */}
        <Price>{price}</Price>
      </PriceContaienr>
      <UsuallyButton onClick={onPress} title="Дякую!" />
    </Container>
  );
};

export default ResultContent;
