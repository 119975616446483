import React, {Component, ErrorInfo} from 'react';
import {assets} from '../../assets';
import {ErrorBoundaryWrapperStyled, ErrorTestStyled} from './styles';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: Error) {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrapperStyled>
          <ErrorTestStyled>Щось пішло не так.</ErrorTestStyled>
        </ErrorBoundaryWrapperStyled>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
