export type TContainerStyle = {
  $size: string;
  isOpponent?: boolean;
  status: EStatusFightAvatar;
};

export type TProps = TContainerStyle & {
  url?: string;
  status: EStatusFightAvatar;
  title?: string;
  subTitle?: string;
  isOpponent?: boolean
};

export type TImageStyle = {
  isOpponent?: boolean;
  status: EStatusFightAvatar;
  $size: string;
}

export enum EStatusFightAvatar {
  READY = 'READY',
  SEARCHING = 'SEARCHING',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}
