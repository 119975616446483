import React, { useCallback, useEffect } from 'react';
import WebApp from '@twa-dev/sdk'
import { useLocation } from 'react-router-dom';
import { selectUser, setInitialUserData } from '../reducers/user';
import { useAddIncomeMutation, useAppDispatch, useAppSelector, useGetOrCreateUserQuery, useLazyGetOrCreateUserQuery } from '.';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const usePageUnload = () => {
  const query = useQuery();  
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector(selectUser);
  const [addIncome] = useAddIncomeMutation();
  const [getOrCreateUserQuery] = useLazyGetOrCreateUserQuery();
  
  const initialData = WebApp.initData;
  const referral_code = query.get('referral_code');
  const chat_id = query.get('chat_id');

  const handleClose = useCallback(async () => {
    try {
      // const response = await getOrCreateUserQuery(`${initialData}&referral_code=${referral_code}&chat_id=${chat_id}`).unwrap();
      const response = await getOrCreateUserQuery(
        `query_id=AAG_b3FTAAAAAL9vcVPGMjKI&user=%7B%22id%22%3A1399943103%2C%22first_name%22%3A%22Victoriya%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SViktoriyaS%22%2C%22language_code%22%3A%22uk%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728285518&hash=11b6a85fdb54400e8f343102dec6db5606555fbc398d8de0366381c822f56b0e&referral_code=y2dgpUtROXERD4EnZ54KZQjY85ZJtsdj&chat_id=1399943103
      `,).unwrap(); //Vika
      
      dispatch(setInitialUserData(response));
      if (user && response && user.coins_num !== response.coins_num) {
        const coins_num = user.coins_num - response.coins_num;
        await addIncome({ coins_num: parseInt(coins_num.toFixed(0), 10), id: response.id });
      }
    } catch(error) {
      console.error(error);
    }
    WebApp.showPopup(
      {
        message: 'Are you sure you want to close this window?',
        buttons: [{ id: 'close', type: 'close' }]
      }, (id) => id && WebApp.close()
    )
  }, [user]);


  useEffect(() => {
    WebApp.BackButton.isVisible = true
    WebApp.BackButton.onClick(handleClose);

    return () => {
      WebApp.BackButton.offClick(handleClose);
    }
  }, [handleClose]);
};

export default usePageUnload;
