import React, {useCallback, useEffect, useState} from 'react';
import {EggItem, FlyingNumber, InfoBlock, Timer} from './component';
import {Container, GameContainer, Header} from './styles';
import {eggsData} from './constants';
import {useNavigate} from 'react-router-dom';
import {
  useSetUserFightScoreMutation,
  useGetUserFightsDataQuery,
  useAppSelector,
  useAppDispatch,
} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {selectFight, setTupData} from '../../reducers/fight';
import EndGame from './component/EndGame/EndGame';
import {TFlyingNumberPosition} from './types';
import dayjs from 'dayjs';
import { BackgroundImgContainer } from '../../components';

type TProps = {};
const checkTimeDifference = (timestampInMilliseconds: number | null): number => {
  if (timestampInMilliseconds) {
    const currentTime = dayjs().valueOf();
    const differenceInMilliseconds = currentTime - timestampInMilliseconds;
    const differenceInSeconds = differenceInMilliseconds / 1000;
    return differenceInSeconds <= 60 ? Math.floor(60 - differenceInSeconds) : 0;
  } else {
    return 60;
  }
};

const Game: React.FC<TProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {data: user} = useAppSelector(selectUser);
  const {lastFightId, tupData} = useAppSelector(selectFight);

  // const {data: activeFightData} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});

  const {data: fightData, refetch} = useGetUserFightsDataQuery({user_id: user?.id ?? ''});

  const [setUserFightScore, {isLoading, error, data: setUserFightScoreResponce, isSuccess}] =
    useSetUserFightScoreMutation();

  const [isGameEnd, setIsGameEnd] = useState<boolean>(false);
  const [numberPositions, setNumberPositions] = useState<TFlyingNumberPosition[]>([]);

  const setGameEndHandler = useCallback(
    (userId: string | null, gameId: string | null, counter: number) => () => {
      setIsGameEnd(true);
      if (userId && gameId) {
        setUserFightScore({
          userId,
          id: gameId,
          score: counter,
          body: {score: counter},
        });
      }
    },
    [],
  );

  const onClickEggHandler = useCallback(
    (itemCoast: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = rect.x + rect.width / 2;
      const y = rect.y - rect.height;
      const endY = y - event.currentTarget.offsetTop;
      const id = `${Date.now()}-${Math.random()}`;
      setNumberPositions(prevPositions => [...prevPositions, {id, x, y, amount: itemCoast, endY}]);

      const newAmount = (tupData?.amount ?? 0) + itemCoast;
      dispatch(setTupData({timeTup: tupData?.timeTup, amount: newAmount}));
    },
    [tupData?.amount, tupData?.timeTup],
  );

  const onClickNext = useCallback(() => {
    navigate('/fight-result');
  }, []);

  const removeAnimatedComponent = (id: string | number) => {
    setNumberPositions(prev => prev.filter(item => item.id !== id));
  };

  useEffect(() => {
    if (!tupData?.timeTup && tupData?.amount === null) {
      console.log("зашли")
      const now = dayjs().valueOf();
      console.log(now, "now")
      dispatch(setTupData({amount: 0, timeTup: now}));
    }
  }, [tupData?.timeTup, tupData?.amount]);

  return (
    <BackgroundImgContainer gradient={`linear-gradient(34.07deg, #001956 22.09%, #1F91CA 58.06%, #0FADAC 76.41%, #01CF8F 93.38%)`}>
    <Container>
      {!isGameEnd ? (
        <>
          <Header>
            <InfoBlock  isStart={!isGameEnd} timeCounter={checkTimeDifference(tupData?.timeTup)} onTimeEnd={setGameEndHandler(user?.id ?? null, lastFightId, tupData?.amount ?? 0)} amount={tupData?.amount ?? 0} />
            {/* <Timer
              timeCounter={checkTimeDifference(tupData?.timeTup)}
              isStart={!isGameEnd}
              onTimeEnd={setGameEndHandler(user?.id ?? null, lastFightId, tupData?.amount ?? 0)}
            /> */}
          </Header>
          <GameContainer>
            <EggItem
              $width={eggsData[0].width}
              $height={eggsData[0].height}
              src={eggsData[0].src}
              onPress={onClickEggHandler(eggsData[0].coast)}
            />
            <EggItem
              $width={eggsData[1].width}
              $height={eggsData[1].height}
              src={eggsData[1].src}
              onPress={onClickEggHandler(eggsData[1].coast)}
            />
            <EggItem
              $width={eggsData[2].width}
              $height={eggsData[2].height}
              src={eggsData[2].src}
              onPress={onClickEggHandler(eggsData[2].coast)}
            />
            <EggItem
              $width={eggsData[2].width}
              $height={eggsData[3].height}
              src={eggsData[3].src}
              onPress={onClickEggHandler(eggsData[3].coast)}
            />
            {numberPositions.map(({id, x, y, endY, amount}) => (
              <FlyingNumber
                key={id}
                id={id}
                x={x}
                y={y}
                endY={endY}
                setNumberPositions={() => removeAnimatedComponent(id)}
                amount={amount}
              />
            ))}
          </GameContainer>
        </>
      ) : (
        <EndGame onClick={onClickNext} counter={tupData?.amount ?? 0} />
      )}
    </Container>
    </BackgroundImgContainer>
  );
};

export default Game;
