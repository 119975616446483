import {assets} from '../../assets';

export const eggsData = [
  {
    src: assets.smallEgg,
    height: '90px',
    width: '90px',
    coast: 5,
  },
  {
    src: assets.mediumEgg,
    height: '100px',
    width: '100px',
    coast: 4,
  },
  {
    src: assets.largeEgg,
    height: '120px',
    width: '120px',
    coast: 3,
  },
  {
    src: assets.extraLargeEgg,
    height: '150px',
    width: '150px',
    coast: 2,
  },
];
