import Reqct, {useMemo} from 'react';
import {Container, CurrentLvl, ProgressBar, ProgressContainer, Title, TitleContainer, TotalLvl, Value} from './styles';
import {useAppSelector} from '../../../hooks';
import {selectUser} from '../../../reducers/user';
import {selectLevels} from '../../../reducers/levels';

const LvlProgress: React.FC = () => {
  const {data: userData} = useAppSelector(selectUser);
  const data = useAppSelector(selectLevels);

  const userLvl = useMemo(
    () => data.find(item => item.name === userData?.level.name)?.level ?? 1,
    [data, userData?.level],
  );

  const percent = (userLvl / data.length) * 100;

  return userData ? (
    <Container>
      <TitleContainer>
        <Title>Твій рівень</Title>
        <Value>
          <CurrentLvl>{userLvl}</CurrentLvl>
          <TotalLvl>/{data.length}</TotalLvl>
        </Value>
      </TitleContainer>
      <ProgressContainer>
        <ProgressBar percentage={percent} />
      </ProgressContainer>
    </Container>
  ) : null;
};

export default LvlProgress;
