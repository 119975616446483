export const calculateHeight = (width: number, originalWidth: number, originalHeight: number) => {
    const height = (originalHeight / originalWidth) * width;
    return height;
  }

export const calculateOverlayWidth =(outerWidth: number) =>  {
   const ratio = [48, 36];
    const proportion = ratio[1] / ratio[0];
    const innerWidth = outerWidth * proportion;
  
    return innerWidth;
  }