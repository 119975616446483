import styled from "styled-components";
import { calculateHeight, calculateOverlayWidth } from "./utils";
import { TImage } from "./types";

export const Image = styled.img<TImage>`
  width: ${({baseWidth, isVw}) => `${baseWidth}${isVw ? 'vw' : 'px'}`};
  z-index: 666;
`;

export const Overlay = styled.div<TImage>`
  width: ${({baseWidth, isVw}) => `${calculateOverlayWidth(baseWidth)}${isVw ? 'vw' : 'px'}`};
  height: ${({baseWidth, isVw}) => `${calculateHeight(calculateOverlayWidth(baseWidth), 103, 86)}${isVw ? 'vw' : 'px'}`};
  background: rgba(255, 255, 255, 1);
  mix-blend-mode: overlay;
  border-radius: 50%;
  filter: blur(32px);
  position: absolute;
  bottom: 1vw;
  box-shadow: inset -20px 4px 30px #042864;
`

export const ImageContainer = styled.div<TImage>`
position: relative;
width: ${({baseWidth, isVw}) => `${baseWidth}${isVw ? 'vw' : 'px'}`};
height: ${({baseWidth, isVw}) => `${baseWidth}${isVw ? 'vw' : 'px'}`};
display: flex;
justify-content: center;
align-items: center;
`;