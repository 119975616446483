import {Box, Button, Typography} from '@mui/material';
import {CodeContainer, CopyButtonContainer, CopySVGContainer, styles} from './styles';
import {TMainer} from '../../types/entities';
import {useCallback, useMemo, useState} from 'react';
import {Styles} from '../../types';
import {BASE_URL_FOR_ASSETS} from '../../services/api/api';
import {formattedNumberByСards} from '../../helpers';
import {ReactComponent as CrystalSVG} from '../../assets/icons/crystal.svg';
import {TPromoCode} from '../../services/api/promoCodes/types';
import Loader from '../Loader/Loader';

type TProps = {
  item: TPromoCode;
  coins: number;
  isDisable: boolean;
  isLoading: boolean;
  onClick: (item: TPromoCode) => void;
};

const PromoRow = ({item, coins, onClick, isDisable, isLoading}: TProps) => {
  const [look, setLook] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    if (item.code) {
      setLook(true);
    } else {
      if (coins >= item.price) {
        onClick(item);
      }
    }
  }, [item, onClick, setLook, coins]);

  const handleCopy = useCallback(() => {
    if (item.code) {
      navigator.clipboard
        .writeText(item.code)
        .then(() => {})
        .catch(err => {});
    }
  }, [item.code]);

  return (
    <div onClick={handleClick}>
      <Box sx={styles.container}>
        <Box sx={styles.infoView}>
          <Typography sx={styles.title}>{item.name}</Typography>
          <Box sx={styles.imgView}>
            <img
              srcSet={`${BASE_URL_FOR_ASSETS + item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${BASE_URL_FOR_ASSETS + item.image}?w=164&h=164&fit=crop&auto=format`}
              alt={item.name}
              style={{width: '32px', height: '32px'}}
              loading="lazy"
            />
          </Box>
        </Box>
        <Box sx={{...styles.improveLevelView, ...((look || isDisable) && styles.disable)} as Styles}>
          {look && isDisable ? (
            <CodeContainer>
              <Typography sx={styles.improveLevelPrice}>{item.code}</Typography>
            </CodeContainer>
          ) : (
            <Box sx={styles.improveLevelPriceContainer}>
              <CrystalSVG />
              <Typography sx={styles.improveLevelPrice}>{formattedNumberByСards(item.price)}</Typography>
            </Box>
          )}
          {!look ? (
            <Typography sx={styles.improveLevelTitle}>{item.code ? 'Look at Code' : 'Buy Code'}</Typography>
          ) : (
            <CopyButtonContainer onClick={handleCopy}>
              <CopySVGContainer />
            </CopyButtonContainer>
          )}
        </Box>
        {isLoading ? (
          <div style={{position: 'absolute', display: 'flex', flex: 1}}>
            <Loader />
          </div>
        ) : null}
      </Box>
    </div>
  );
};

export default PromoRow;
