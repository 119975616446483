import styled from 'styled-components';
import {colors} from '../../constants';
import { Styles } from '../../types';

export const Title = styled.h1`
  font-size: 20px;
  color: ${colors.white};
  margin-bottom: 8px;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  padding: 24px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const AvatarsBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

export const FightButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Devider = styled.span`
  text-align: center;
  color: ${colors.white};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 60px;
  align-self: center;
`
export const styles: Styles = {
  btnStyle: {
    height: '48px',
  },
};