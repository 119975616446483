import styled from 'styled-components';
import { TBackground } from './types';

export const BackgroundDiv = styled.div<TBackground>`
  width: 100vw;
  height: calc(100vh - 85px);
  background: ${({gradient}) => gradient ? gradient : 'linear-gradient(to bottom right, rgba(46, 185, 255, 0.7) 0%, rgba(0, 0, 0, 0) 70%)'};
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
`;
