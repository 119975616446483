import React from 'react';
import {Box, Typography} from '@mui/material';
import {styles, SvgContainer} from './styles';
import {formattedNumber} from '../../../helpers';
import {ReactComponent as CrystalSVG} from '../../../assets/icons/crystal.svg';

type TProps = {
  coins: number;
};

const CoinTotalTracker = ({coins}: TProps) => {
  return (
    <Box sx={styles.container}>
      <SvgContainer>
        <CrystalSVG />
      </SvgContainer>
      <Typography sx={styles.value}>{formattedNumber(Math.floor(coins))}</Typography>
    </Box>
  );
};

export default CoinTotalTracker;
