import {useEffect, useState} from 'react';
import React from 'react';
import {Container,AmountContainer} from './styles';
import Timer from '../Timer/Timer';
import { GradientBunner } from '../../../../components';

type TProps = {
  isStart: boolean;
  timeCounter: number;
  onTimeEnd: () => void;
  amount: number;
};

const InfoBlock: React.FC<TProps> = ({isStart, onTimeEnd, timeCounter, amount}) => {


  return <Container>
   <Timer isStart={isStart} timeCounter={timeCounter} onTimeEnd={onTimeEnd} />
  <GradientBunner text={amount.toString()}  />
  </Container>;
};

export default InfoBlock;