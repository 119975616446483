import {urls} from '../../../constants';
import {TUser} from '../../../types/entities';
import {splitApi} from '../api';
import {TAddPromoCodeResponce, TGetPromoCodesPayload, TPromoCode} from './types';

export const promoCodesApi = splitApi.injectEndpoints({
  endpoints: build => ({
    getPromoCodes: build.query<TPromoCode[], TGetPromoCodesPayload>({
      query: ({user_id}) => ({
        method: 'GET',
        url: `api/v1/${user_id}/promo-codes/`,
      }),
    }),
    addPromoCodes: build.mutation<
      TAddPromoCodeResponce,
      {user_id: string; data: {promo_code_id: string; coins_income: number}}
    >({
      query: ({user_id, data}) => ({
        method: 'POST',
        url: `api/v1/${user_id}/promo-codes/add`,
        body: data,
      }),
    }),
  }),
});

export const {useGetPromoCodesQuery, useAddPromoCodesMutation} = promoCodesApi;
