import React, {useMemo} from 'react';
import {
  Container,
  InfoContainer,
  Initial,
  Points,
  PointsContainer,
  Status,
  StatusContainer,
  TopSideContainer,
} from './styles';
import {AvatarContainer} from '../../../../components';
import {EStatusFightAvatar} from '../../../../components/layout/AvatarContainer/types';
import {colors} from '../../../../constants';
import {EFightStatus} from '../../../../types/entities/fight';
import {ReactComponent as EnegrgySVG} from '../../../../assets/icons/energy.svg';


type TProps = {
  url: string;
  fio: string;
  fightStatus: EFightStatus;
  points: number | null;
};

const UserFightInfoItem: React.FC<TProps> = ({url, fio, points, fightStatus}) => {
  const badgeData = useMemo(() => {
    if (points !== null || fightStatus === EFightStatus.FINISH) {
      return {
        label: 'Fight complete',
      };
    }
    return {
      label: 'Waiting',
    };
  }, [fightStatus, points]);
  return (
    <Container>
      <AvatarContainer $size={'120px'} status={EStatusFightAvatar.NOT_AVAILABLE} url={url} />
      <InfoContainer>
        <TopSideContainer>
          <Initial>{fio}</Initial>
          <StatusContainer $color={`rgba(255, 255, 255, 0.5)`}>
            <Status $color={colors.white}>{badgeData.label}</Status>
          </StatusContainer>
        </TopSideContainer>
        <PointsContainer>
          <EnegrgySVG />
          <Points>{points !== null ? `${points}` : '--'}</Points>
        </PointsContainer>
      </InfoContainer>
    </Container>
  );
};

export default UserFightInfoItem;
