import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TGlobalState} from '../types/reducers';
import {TMainer, TUser} from '../types/entities';
import {currentTimestampInSeconds, getLevelFromCoins, totalEarnings} from '../helpers';

type TInitialState = TGlobalState['user'];

type changeUserLevelActionPayload = {
  data: {
    item: {
      coins_count: number;
      coins_per_click: number;
      id: string;
      level: number;
      name: string;
    }
  };
  level: number;
};


export const userInitialState: TInitialState = {
  data: null,
  initialUserData: null,
  backgroundMining: 0,
  lastCoinUpdate: 0,
};

const userSlice = createSlice({
  name: '@@user',
  initialState: userInitialState,
  reducers: {
    setUserDataAction: (state, action: PayloadAction<TUser>) => {
      state.data = action.payload;
      state.backgroundMining = 0;
    },
    updateUserAction: (state, action) => {
      state.data = {...state.data, ...action.payload};
    },
    setInitialUserData: (state, action) => {
      state.initialUserData = {...state.initialUserData, ...action.payload};
    },
    upgradeUserLevelAction: state => {
      // if (state.data) {
      //   const level = getLevelFromCoins(state.data.coins_num);
      //   if (level < state.data.level) {
      //     return;
      //   }
      //   state.data.level = level;
      // }
    },
    changeUserLevelAction: (state, action: PayloadAction<changeUserLevelActionPayload>) => {
      if (state.data) {
        const { data: { item }, level } = action.payload;

        state.data.level = item;
        state.data.level_num = level;
      }
    },    
    incrementCoinAction: state => {
      if (state.data) {
        state.data.coins_num += 1;
        state.lastCoinUpdate = currentTimestampInSeconds();
      }
    },
    setCoinsAction: (state, action) => {
      if (state.data) {
        state.data.coins_num += action.payload;
        state.lastCoinUpdate = currentTimestampInSeconds();
      }
    },

    upgradeMainer: (state, action: PayloadAction<{newMainer: TMainer; price: number}>) => {
      if (state.data) {
        const index = state.data.cards.findIndex(el => el.id === action.payload.newMainer.id);
        if (index !== -1) {
          state.data.cards[index] = action.payload.newMainer;
          state.data.coins_num -= action.payload.price;
          userSlice.caseReducers.updateBackgroundMining(state);
        }
      }
    },
    updateBackgroundMining: state => {
      if (state.data) {
        const totalEarningsData = totalEarnings(state.data.cards);
        const totalEarningsPerSecond = totalEarningsData / 3600;
        if (totalEarningsPerSecond) {
          state.backgroundMining = totalEarningsPerSecond;
        }
      }
    },

    resetUserAction: () => userInitialState,
  },
});

// actions
export const {
  setUserDataAction,
  incrementCoinAction,
  resetUserAction,
  upgradeMainer,
  updateBackgroundMining,
  setCoinsAction,
  updateUserAction,
  upgradeUserLevelAction,
  changeUserLevelAction,
  setInitialUserData
} = userSlice.actions;

// reducer
export const user = userSlice.reducer;

// selectors
export const selectUser = (state: TGlobalState) => state.user;
