import {colors} from '../../../constants';
import {Styles} from '../../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '47px',
    background: 'rgba(255, 255, 255, 0.11)',
    borderRadius: '16px',
  },
  button: {
    position: 'relative',
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
  },
  activeBtn: {
    background: `linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`,
  },
  title: {
    color: '#B6B6B6',
    fontSize: '16px',
    textTransform: 'none',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    marginLeft: '8px',
  },
  activeTitle: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: '700',
  },
};
