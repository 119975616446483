import React from 'react';
import {AcceptButtonContainer, Container, ContentContainer, CounterContainer, Description,  Title} from './styles';
import {BackgroundImgContainer, GradientBunner, ImageWithOverlay, UsuallyButton} from '../../../../components';
import {assets} from '../../../../assets';
import { colors } from '../../../../constants';

type TProps = {
  onClick: () => void;
  counter: number;
};


const EndGame: React.FC<TProps> = ({onClick, counter}) => {
  return (
<BackgroundImgContainer gradient={`linear-gradient(34.07deg, #001956 22.09%, #1F91CA 58.06%, #0FADAC 76.41%, #01CF8F 93.38%)`}>
      <Container>
        <ContentContainer>
        <ImageWithOverlay baseWidth={48} isVw src={assets.fightIsEnd} />
        <Title>Бій закінчено</Title>
        <Description>Ви зібрали</Description>
        <CounterContainer>
          <GradientBunner text={`${counter > 0 ? `${counter}` : counter}`} />
        </CounterContainer>
        </ContentContainer>
        <AcceptButtonContainer>
          <UsuallyButton title="Get it!" onClick={onClick} />
        </AcceptButtonContainer>
      </Container>
    </BackgroundImgContainer>
  );
};

export default EndGame;
