import styled from 'styled-components';

export const Container = styled.div<{$height: number}>`
  align-items: center;
  width: 100%;
  vertical-align: center;
  text-align: center;
  justify-content: center;
  height: ${({$height}) => $height}px;
`;

export const ProgressDiagramContainer = styled.div<{$width: number}>`
  width: ${({$width}) => $width}px;
  margin-left: -10px;
`;
