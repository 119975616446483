import React, {useCallback, useEffect, useState} from 'react';
import {useAppSelector, useGetReferralQuery, useLazyGetReferralQuery} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {Box, Button, Typography} from '@mui/material';
import {CopySVGContainer, CrystalSVGContainer, InfoContainer, InviteFriendsSVGContainer, styles} from './styles';
import RowItem from './components/RowItem/RowItem';
import {BackgroundImgContainer, Loader} from '../../components';
import {selectReferral} from '../../reducers/referral';

const Friends = () => {
  const {data: user} = useAppSelector(selectUser);
  const {referrals, total_earn} = useAppSelector(selectReferral);
  // const [getReferralQuery, {isLoading, data: userRefferal}] = useLazyGetReferralQuery();
  const {data, isLoading} = useGetReferralQuery({user_id: user?.id ?? ''}, {skip: !user?.id});
  // const [referralUrl, setReferralUrl] = useState<string>('');
  // const [referralCode, setReferralCode] = useState<string>('');

  if (!user) {
    return <></>;
  }

  // useEffect(() => {
  //   // getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     const {data} = await getReferralQuery({user_id: user.id});
  //     if (data?.referral_code) setReferralCode(data?.referral_code);
  //     if (data?.referral_url) setReferralUrl(data?.referral_url);
  //   } catch (error) {}
  // };

  const handleShare = useCallback(() => {
    if (data) {
      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        data.referral_url,
      )}&text=${encodeURIComponent(
        'Агов, приєднуйся до мене в MoneyClicker! Клікай, щоб заробляти монети та отримувати шалені знижки в MyCredit !',
      )}`;
      window.open(telegramUrl, '_blank');
    }
  }, [data?.referral_url]);

  const handleCopy = useCallback(() => {
    if (data) {
      navigator.clipboard
        .writeText(data?.referral_url)
        .then(() => {})
        .catch(err => {});
    }
  }, [data?.referral_url]);

  return (
    <BackgroundImgContainer>
      <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={styles.container}>
            <Box>
              <Box sx={styles.headStyle}>
                <Typography sx={styles.title}>Друзі</Typography>
                <Typography sx={styles.description}>
                  Запрошуй друзів і ми разом! Гравці з найбільшою мережею друзів зазвичай отримують найвищі бонуси
                </Typography>
              </Box>
              <RowItem coins={15000} title={'Запросити друга'} />
              <Box sx={styles.referralStatusView}>
                <Typography sx={styles.referralStatusTitle}>Ваш статус реферала</Typography>
                <InfoContainer>
                  <Box sx={styles.referralInfoView}>
                    <Typography sx={styles.referralInfoTitle}>Запрошуй людей</Typography>
                    <Box sx={styles.referralInfoRightView}>
                      <InviteFriendsSVGContainer />
                      <Typography sx={styles.count}>{referrals}</Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.referralInfoView}>
                    <Typography sx={styles.referralInfoTitle}>Зароблено вашими рефералами</Typography>
                    <Box sx={styles.referralInfoRightView}>
                      <CrystalSVGContainer />
                      <Typography sx={styles.count}>{total_earn}</Typography>
                    </Box>
                  </Box>
                </InfoContainer>
              </Box>
            </Box>
            <Box sx={styles.actionView}>
              <Button onClick={handleShare} sx={styles.invite}>
                <Typography sx={styles.buttonText}>Запросити зараз</Typography>
              </Button>
              <Button onClick={handleCopy} sx={styles.copy}>
                <CopySVGContainer />
              </Button>
            </Box>
          </Box>
        )}
      </div>
    </BackgroundImgContainer>
  );
};

export default Friends;
