import styled from 'styled-components';
import {colors} from '../../../constants';

export const HeaderContainer = styled.div`
  margin-top: 26px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  height: 100%;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;
