import styled from 'styled-components';
import {colors} from '../../../../constants';

export const Container = styled.div`
  padding: 0px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const ContentContainer = styled.div`
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
display: flex;
align-items: center;
justify-content: center;
`
export const Title = styled.p`
  font-size: 20px;
  padding: 0;
  margin-top: 32px;
  color: ${colors.white};
  margin-bottom: 8px;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  margin-bottom: 24px;
`;

export const AcceptButtonContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      stroke: ${colors.black_1A1A1A};
    }
  }
  span {
    color: ${colors.black_1A1A1A}
  }
`;


