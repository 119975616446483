import {useEffect} from 'react';
import {useAppDispatch, useAppSelector, useLazyGetLevelsQuery, useUpdateLevelMutation} from '.';
import {selectLevels, setLevelsDataAction} from '../reducers/levels';
import {changeUserLevelAction, selectUser} from '../reducers/user';

interface Item {
  id: string;
  name: string;
  coins_count: number;
  coins_per_click: number;
  level: number;
}

type resultLevel = {
  data: {
    item: {
      coins_count: number;
      coins_per_click: number;
      id: string;
      level: number;
      name: string;
    };
  };
  level: number;
};

const useCheckLevels = () => {
  const dispatch = useAppDispatch();
  const [getLevelsQuery] = useLazyGetLevelsQuery();
  const [updateLevelUser] = useUpdateLevelMutation();
  const {data: user} = useAppSelector(selectUser);

  const levels = useAppSelector(selectLevels);

  const addLevelsToItems = (items: Item[]) => {
    return items.map((item, index) => ({
      ...item,
      level: index + 1,
    }));
  };

  const filterItemsByCoins = (items: Item[], userCoins: number, currentLevel: number) => {
    const data = items
      .map(item => ({item}))
      .filter(({item}) => (userCoins >= item.coins_count && item.level >= currentLevel) || item.level === currentLevel);
    const result = data[data.length - 1];

    return {
      level: result?.item?.level,
      data: result,
    };
  };

  const getInitialLevels = async () => {
    try {
      const response = await getLevelsQuery().unwrap();
      if (response) {
        const levelsWithLevels = addLevelsToItems(response);

        if (!levels.length) {
          dispatch(setLevelsDataAction({data: levelsWithLevels}));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const findUserLevelObject = (levels: Item[], userLevelId: string) => {
    return levels.find(level => level.id === userLevelId);
  };

  const updateLevel = async (level: resultLevel) => {
    if (user && user.level.id !== level.data.item.id) {
      await updateLevelUser({userId: user.id, levelId: level.data.item.id});
    }
  };

  useEffect(() => {
    if (!levels.length) {
      getInitialLevels();
    }

    if (levels.length && user?.level?.id) {
      const userLevelObject = findUserLevelObject(levels, user.level.id);
      if (userLevelObject) {
        const result = filterItemsByCoins(levels, user.coins_num, userLevelObject.level);
        if (result?.data?.item) {
          dispatch(changeUserLevelAction(result));
          updateLevel(result);
        }
      }
    }
  }, [levels, user]);
};

export default useCheckLevels;
