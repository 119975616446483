import styled from 'styled-components';
import {colors} from '../../../../constants';

export const Container = styled.div`
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.5em;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 43px;
  background: rgba(255, 255, 255, 0.23);
  backdrop-filter: blur(8px);
`;

export const TimerText = styled.span`
font-size: 16px;
font-weight: 700;
color: ${colors.white}
`