import React, {useCallback} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import UsuallyButton from '../../navigation/UsuallyButton/UsuallyButton';
import {useNavigate} from 'react-router-dom';
import {colors} from '../../../constants';

type TProps = {};

const Support = ({}: TProps) => {
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    navigate('/coming-soon');
  }, []);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Якщо у вас виникли запитання, не соромтеся звертатися до нашої служби підтримки.</Typography>
      <Box sx={styles.button}>
        <UsuallyButton
          title="Напишіть нам"
          leftIcon="pencil"
          background={`linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`}
          onClick={handlePress}
        />
      </Box>
    </Box>
  );
};

export default Support;
