import {Box, Typography} from '@mui/material';
import {Button, StyledImageLeft, StyledImageRight, styles} from './styles';
import {ReactSVG} from 'react-svg';
import {assets} from '../../../assets';
import {ReactComponent as FightSVG} from '../../../assets/icons/fight.svg';

type TProps = {
  title?: string;
  containerStyle?: any;
  onClick: () => void;
};

const FightWithFriendsButton = ({title, onClick, containerStyle}: TProps) => {
  return (
    <Button onClick={onClick}>
      <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <>
          <FightSVG />
          {title ? <Typography sx={styles.title}>{title}</Typography> : null}
        </>
      </Box>

      <StyledImageLeft src={assets.hostBearMin} alt="FightFriendsPNG" />
      <StyledImageRight src={assets.opponentBearMin} alt="FightFriendsPNG" />
    </Button>
  );
};

export default FightWithFriendsButton;
