import styled from 'styled-components';
import {colors} from '../../../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  svg {
    path {
      stroke: ${colors.black_1A1A1A};
    }
  }
`;

export const AmountContainer = styled.div`
  border: none;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    ${colors.main_gradient_first_color_01CF8F} 0%,
    ${colors.main_gradient_second_color_0FADAC} 100%
  );;
  color: white;
  font-size: 1.5em;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 43px;

`
