import {Box, Typography} from '@mui/material';
import {ReactSVG} from 'react-svg';
import {styles} from './styles';
import NextSVG from '../../../../assets/icons/next.svg';
import CoinSVG from '../../../../assets/icons/crystal.svg';
import CalendarSVG from '../../../../assets/icons/calendar.svg';
import CursorSVG from '../../../../assets/icons/cursor.svg';
import HoopsSVG from '../../../../assets/icons/hoops.svg';
import SharingSVG from '../../../../assets/icons/sharing.svg';

import {useCallback} from 'react';

type TProps = {
  icon: 'Calendar' | 'Cursor' | 'Hoops' | 'Sharing';
  coins?: number;
  disabled?: boolean;
  title: string;
  onClick: () => void;
};

const getIcon = (title: 'Calendar' | 'Cursor' | 'Hoops' | 'Sharing') => {
  switch (title) {
    case 'Calendar':
      return CalendarSVG;
    case 'Cursor':
      return CursorSVG;
    case 'Hoops':
      return HoopsSVG;
    case 'Sharing':
      return SharingSVG;
    default:
      return NextSVG;
  }
};

const RowItem = ({disabled = false, coins, title, icon, onClick}: TProps) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div
      onClick={handleClick}
      style={{width: '100%', minHeight: '66px', opacity: disabled ? 0.4 : 1, marginBottom: '8px'}}>
      <Box sx={styles.container}>
        <Box sx={styles.iconView}>
          <ReactSVG
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
            }}
            src={getIcon(icon)}
          />
        </Box>
        <Box sx={styles.infoView}>
          <Typography sx={styles.title}>{title}</Typography>
          {coins ? (
            <>
              <Box sx={styles.coinsView}>
                <ReactSVG
                  src={CoinSVG}
                  beforeInjection={svg => {
                    svg.setAttribute('style', 'width: 16px; height: 16px; display: block;');
                  }}
                />
                <Typography sx={styles.coins}>+{coins}</Typography>
              </Box>
            </>
          ) : null}
        </Box>
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
          src={NextSVG}
        />
      </Box>
    </div>
  );
};

export default RowItem;
