import React from 'react';
import {Container, ItemValue, LeftBlockContainer, RightBlockContainer, Row, StyledCrystalSVG, Title} from './styles';
import {TProps} from './types';
import {formattedNumberByСards} from '../../../helpers';
import LvlProgress from '../LvlProgress/LvlProgress';

const BriefCoinInfo: React.FC<TProps> = ({backgroundMining, profitPerTap = 1}) => {
  return (
    <Container>
      <LeftBlockContainer>
        <Row>
          <Title>Прибуток за клік</Title>
          <ItemValue>
            <StyledCrystalSVG />+{profitPerTap}
          </ItemValue>
        </Row>
        <Row>
          <Title>Прибуток за годину</Title>
          <ItemValue>
            <StyledCrystalSVG />+{formattedNumberByСards(Number((backgroundMining * 3600).toFixed(0)))}
          </ItemValue>
        </Row>
      </LeftBlockContainer>
      <RightBlockContainer>
        <LvlProgress />
      </RightBlockContainer>
    </Container>
  );
};

export default BriefCoinInfo;
