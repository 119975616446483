import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {userApi} from '../services/api/user';
import {AppDispatch, RootState} from '../store';
import {rewardApi} from '../services/api/reward';
import {referralApi} from '../services/api/referral';
import {roadMapApi} from '../services/api/roadMap';
import {fightlApi} from '../services/api/fight';
import {addIncomeApi} from '../services/api/addIncome';
import {levelsApi} from '../services/api/levels';
import {boostApi} from '../services/api/boost';
import {promoCodesApi} from '../services/api/promoCodes/promoCodes';
export {useCheckFightStatus} from './useCheckFightStatus';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const {useLazyGetOrCreateUserQuery, useLazyUpdateUserQuery, useGetOrCreateUserQuery} = userApi;
export const {useAddIncomeMutation} = addIncomeApi;
export const {useLazyGetRewardsQuery, useLazyUpdateDailyRewardQuery, useUpdateSocialMediaRewardMutation} = rewardApi;
export const {useLazyGetReferralQuery, useGetReferralQuery} = referralApi;
export const {useLazyGetRoadMapQuery} = roadMapApi;
export const {useLazyGetLevelsQuery, useUpdateLevelMutation} = levelsApi;
export const {useUpdateUserCardMutation} = boostApi;
export const {
  useLazyGetUserFightsDataQuery,
  useToggleUserFightStatusMutation,
  useGetUserFightsDataQuery,
  useSetUserFightScoreMutation,
  useGetFightQuery,
} = fightlApi;
export const {useGetPromoCodesQuery, useAddPromoCodesMutation} = promoCodesApi;

export {default as useFormattedFightData} from './useFormattedFightData';
