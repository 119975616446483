import React, {useEffect, useState} from 'react';
import {Cards, Loader, PromoCodes, RoadMap, Support, Tabs} from '../../components';
import {Box} from '@mui/material';
import {styles} from './styles';
import {useLazyGetRoadMapQuery} from '../../hooks';
import {TGetRoadMapResponse} from '../../services/api/roadMap';

const Store = () => {
  const [index, setIndex] = useState(0);
  const [getRoadMapQuery, {isLoading}] = useLazyGetRoadMapQuery();
  const [roadMapData, setRoadMapData] = useState<TGetRoadMapResponse>([]);

  useEffect(() => {
    getRoadMapData();
  }, []);

  const getRoadMapData = async () => {
    try {
      const {data} = await getRoadMapQuery();
      if (data) {
        setRoadMapData(data);
      }
    } catch (error) {}
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.container}>
          <Tabs
            index={index}
            onClick={setIndex}
            tabData={[
              {title: 'Промокоди', icon: 'support'},
              {title: 'Бустери', icon: 'roadmap'},
            ]}
          />
          {index === 1 ? <Cards /> : <PromoCodes />}
        </Box>
      )}
    </>
  );
};

export default Store;
