import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {TGlobalState} from '../types/reducers';

type TInitialState = TGlobalState['global'];

export const userInitialState: TInitialState = {
  firstOpenApp: true,
};

const globalSlice = createSlice({
  name: '@@global',
  initialState: userInitialState,
  reducers: {
    setFirstOpenAppAction: (state, action: PayloadAction<boolean>) => {
      state.firstOpenApp = action.payload;
    },

    resetGlobalAction: () => userInitialState,
  },
});

// actions
export const {setFirstOpenAppAction, resetGlobalAction} = globalSlice.actions;

// reducer
export const global = globalSlice.reducer;

// selectors
export const selectGlobal = (state: TGlobalState) => state.global;
