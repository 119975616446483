import React from 'react';
import {Container, HeaderContainer} from './styles';
import {CardsContainer, TotalBalance} from '../..';
import {useAppSelector} from '../../../hooks';
import {selectUser} from '../../../reducers/user';

const Cards = () => {
  const {data: user} = useAppSelector(selectUser);

  if (!user) return <></>;

  return (
    <Container>
      <HeaderContainer>
        <TotalBalance coins={user.coins_num} />
      </HeaderContainer>
      <CardsContainer />
    </Container>
  );
};

export default Cards;
