import React from 'react';
import {Container, MainContainer, Title} from './styles';
import {ReactComponent as EnegrgySVG} from '../../../assets/icons/energy.svg';
import { colors } from '../../../constants/colors';

type TProps = {
  text: string;
};

const GradientBunner: React.FC<TProps> = ({text}) => {
  return (
    <MainContainer>
    <Container>
      <EnegrgySVG color={colors.black_1A1A1A} />
      <Title>{text}</Title>
    </Container>
    </MainContainer>
  );
};

export default GradientBunner;
