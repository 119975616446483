import styled from 'styled-components';
import {colors} from '../../../constants';
import {Styles} from '../../../types';
import {TContainer} from './types';

export const styles: Styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isActive: {
    color: colors.background,
  },
  title: {
    textDecoration: 'none',
    color: colors.gray_97AABD,
    fontSize: '14px',
    marginTop: '1px',
    fontWeight: '700',
  },
};

export const Container = styled.div<TContainer>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: ${props => (props.$isFill ? (props.$isActive ? colors.background : colors.gray_97AABD) : 'transparent')};
      stroke: ${props => (props.$isActive ? colors.background : colors.gray_97AABD)};
    }
  }
`;
