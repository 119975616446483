import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {persistStore, persistReducer, PersistConfig} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {rootReducer} from './reducers';
import {splitApiMiddleware} from './services/api/api';

import createFilter from 'redux-persist-transform-filter';

const saveUserFilter = createFilter('user', ['backgroundMining', 'lastCoinUpdate']);

const saveGlobalFilter = createFilter('global', ['firstOpenApp']);

const persistConfig: PersistConfig<RootState> = {
  timeout: 10000,
  key: 'root',
  storage,
  whitelist: ['user', 'global', 'referral', 'fight'],
  transforms: [saveUserFilter, saveGlobalFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const extraArgument = {apiBaseUrl: 'https://dev.api.rooster.myapp.com.ua/api'};

const store = configureStore({
  reducer: persistedReducer,

  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
      serializableCheck: false,
    }).concat(splitApiMiddleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export {store};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
