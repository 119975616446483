import React, {useCallback, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import OneScreen from './components/OneScreen/OneScreen';
import TwoScreen from './components/TwoScreen/TwoScreen';
import ThreeScreen from './components/ThreeScreen/ThreeScreen';
import Progress from './components/Progress/Progress';
import {BackgroundImgContainer, UsuallyButton} from '../..';
import {useLocation} from 'react-router-dom';
import {colors} from '../../../constants';

type TProps = {
  loading: boolean;
  firstOpenApp: boolean;
  onClose: () => void;
};

const Onboarding = ({loading, firstOpenApp, onClose}: TProps) => {
  const [index, setIndex] = useState(1);

  const handleNext = useCallback(() => {
    setIndex(index + 1);
  }, [index]);

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography sx={styles.title}>
          {index === 1
            ? `Натискайте на екран і заробляйте монети`
            : index === 2
            ? 'Покращуйте свій обмін'
            : 'Запрошуй друзів і отримуй бонуси разом'}
        </Typography>
        <Typography sx={styles.description}>
          {index === 1
            ? 'Ви можете обмінювати монети на знижки'
            : index === 2
            ? 'Використовуйте карти, щоб максимізувати свій пасивний дохід'
            : 'Монети отримаєте і ви, і ваш друг'}
        </Typography>
        {index === 1 ? <OneScreen /> : index === 2 ? <TwoScreen /> : <ThreeScreen />}
      </Box>
      <Box
        sx={{
          width: 'calc(100% - 70px)',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          marginBottom: '42px',
        }}>
        {firstOpenApp ? (
          <>
            {index !== 3 ? (
              <Progress index={index} onClick={handleNext} />
            ) : (
              <UsuallyButton
                title={'Play'}
                onClick={onClose}
                background={`linear-gradient(
    to right,
    ${colors.main_gradient_first_color_01CF8F},
    ${colors.main_gradient_second_color_0FADAC},
    ${colors.main_gradient_third_color_1F91CA}
  )`}
              />
            )}
          </>
        ) : (
          <div
            style={{
              border: '16px solid #f3f3f3',
              borderRadius: '50%',
              borderTop: '16px solid #01CF8F',
              width: '20px',
              height: '20px',
              animation: 'spin 2s linear infinite',
            }}></div>
        )}
      </Box>
    </Box>
  );
};

export default Onboarding;
