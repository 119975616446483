import {Box} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {TUser} from '../../../types/entities';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {upgradeUserLevelAction} from '../../../reducers/user';
import {colors} from '../../../constants';
import {getProgress} from '../../../helpers/progress';
import {selectLevels} from '../../../reducers/levels';
import {Container, ProgressDiagramContainer} from './styles';

type TProps = {
  user: TUser;
};

const SemicircleProgressDiagram: React.FC<TProps> = ({user}) => {
  const levels = useAppSelector(selectLevels);
  const [diameter, setDiameter] = useState(0);

  const progress = getProgress(user.level_num, user.coins_num, levels);

  useEffect(() => {
    const updateDiameter = () => {
      const screenWidth = window.innerWidth;
      const newDiameter = screenWidth * 0.75;
      setDiameter(newDiameter);
    };

    updateDiameter();
    window.addEventListener('resize', updateDiameter);
    return () => window.removeEventListener('resize', updateDiameter);
  }, []);

  const circlesData = useMemo(() => {
    const radius = diameter / 2;
    const strokeDasharray = Math.PI * diameter;
    const secondCircleFill = strokeDasharray * (progress / 2 / 100);
    return {
      radius,
      strokeDasharray,
      secondCircleFill,
    };
  }, [diameter, progress]);

  return (
    <Container $height={circlesData.radius + 30}>
      <ProgressDiagramContainer $width={diameter + 60}>
        <svg width={diameter + 60} height={diameter + 30} viewBox={`-20 -20 ${diameter + 30} ${diameter + 30}`}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{stopColor: colors.main_gradient_first_color_01CF8F, stopOpacity: 1}} />
              <stop offset="66%" style={{stopColor: colors.main_gradient_second_color_0FADAC, stopOpacity: 1}} />
              <stop offset="100%" style={{stopColor: colors.main_gradient_third_color_1F91CA, stopOpacity: 1}} />
            </linearGradient>
          </defs>
          <circle
            strokeDasharray={`${circlesData.strokeDasharray / 2} ${circlesData.strokeDasharray / 2}`}
            strokeDashoffset={circlesData.strokeDasharray / 2}
            cx={circlesData.radius}
            cy={circlesData.radius}
            r={circlesData.radius}
            fill="none"
            strokeWidth="15"
            stroke="rgba(255, 255, 255, 0.2)"
            strokeLinecap="round"
          />
          <circle
            strokeDasharray={`${circlesData.secondCircleFill} ${circlesData.strokeDasharray}`}
            strokeDashoffset={-circlesData.strokeDasharray / 2}
            cx={circlesData.radius}
            cy={circlesData.radius}
            r={circlesData.radius}
            fill="none"
            strokeWidth="15"
            strokeLinecap="round"
            stroke="url(#gradient)"
          />
        </svg>
      </ProgressDiagramContainer>
    </Container>
  );
};

export default SemicircleProgressDiagram;
