import {Container} from './styles';
import homeButton from '../../../assets/images/homeButton.png';

type TProps = {
  onClick: () => void;
};

const HomeButton = ({onClick}: TProps) => {
  return (
    <Container onClick={onClick}>
      <img
        src={homeButton}
        alt="homeButton"
        style={{
          width: '32px',
          height: '32px',
          position: 'absolute',
        }}
      />
    </Container>
  );
};

export default HomeButton;
