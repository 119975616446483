export const urls = {
  baseURL: 'http://192.168.0.102:3002',

  //user
  getOrCreateUser: '/api/v1/user/data',
  updateUser: '/api/v1/user/data',

  //reward
  getRewards: '/api/v1/rewards/get-rewards-data',
  updateDailyReward: '/api/v1/rewards/update-daily-reward',
  updateSocialMediaReward: '/api/v1/rewards/update-social-media-reward',
  //referral
  getReferral: '/api/v1/user/referral',

  //levels
  getLevels: '/api/v1/levels',

  //roadMap
  getRoadMap: '/api/v1/roadmap/get-roadmap/',

  //fight
  getFightsUserData: '/api/v1/fights/profile',
};
