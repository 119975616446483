import {Styles} from '../../../types';
import {colors} from '../../../constants';
import styled from 'styled-components';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    fontWeight: '700',
    fontSize: 24,
    color: colors.white,
  },
};

export const SvgContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 90px;
  box-sizing: border-box;
  border: 2px solid ${colors.white};
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
`;
