import styled from 'styled-components';

import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    height: '100vh',
  },
  title: {
    marginTop: '24px',
    fontSize: '20px',
    fontWeight: '700',
    color: 'white',
    textAlign: 'center',
    letterSpacing: '-0.41px',
    marginBottom: '8px',
  },
  description: {
    fontSize: '12px',
    color: colors.white,
    textAlign: 'center',
    marginBottom: '32px',
  },
  typographyDaily: {
    color: 'white',
    textTransform: 'inherit',
    wordWrap: 'break-word',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '12px',
  },
  typographyTasks: {
    marginTop: '16px',
    color: 'white',
    textTransform: 'inherit',
    wordWrap: 'break-word',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '12px',
  },
};

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    padding: 0 26px;
`;

export const ModalContentText = styled.p`
  font-size: 26px;
  line-height: 22px;
  color: ${colors.white};
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.41px;
`;
