import styled from 'styled-components';
import {colors} from '../../../constants';
interface ProgressProps {
  percentage: number;
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 800;
  font-size: 12px;
  color: ${colors.white};
`;

export const Value = styled.span`
  color: ${colors.white};
  font-size: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const CurrentLvl = styled.span`
  font-weight: 700;
`;

export const TotalLvl = styled.span`
  font-weight: 400;
`;
export const ProgressContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0.6rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.2);
`;

export const ProgressBar = styled.div<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({percentage}) => percentage}%;
  border-radius: 16px;
  background: linear-gradient(
    to right,
    ${colors.main_gradient_first_color_01CF8F},
    ${colors.main_gradient_second_color_0FADAC} 66%,
    ${colors.main_gradient_third_color_1F91CA}
  );
  transition: width 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 16px;
    background: transparent;
    border-radius: 16px 0 0 16px;
    z-index: 1;
  }
`;
