import styled from 'styled-components';
import {colors} from '../../../constants';

export const Container = styled.div`
  width: 54px;
  height: 54px;
  bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: linear-gradient(
    to right,
    ${colors.main_gradient_first_color_01CF8F},
    ${colors.main_gradient_second_color_0FADAC},
    ${colors.main_gradient_third_color_1F91CA}
  );
`;
