import styled from 'styled-components';
import {Styles} from '../../../types';
import {Box} from '@mui/material';

export const styles: Styles = {
  typography: {
    marginTop: '32px',
    marginBottom: '24px',
    color: 'white',
    fontSize: '12px',
    letterSpacing: '-0.41px',
  },
  roadMabBox: {
    background: 'rgba(255, 255, 255, 0.11)',
    borderRadius: '16px',
    paddingLeft: '14px',
    paddingTop: '8px',
    width: '100%',
    paddingBottom: '8px',
    paddingRight: '4px',
    marginLeft: '14px',
    borderLeft: '3px solid red',
  },

  wrapper: {
    display: 'flex',
    width: '100%',
  },
  listItem: {
    marginBottom: '8px',
    padding: '0px',
  },
  listItemBackgroundCircle: {
    position: 'absolute',
    zIndex: 2,
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    left: '-23px',
  },
  listItemCircle: {
    width: '16px',
    height: '16px',
    borderRadius: '16px',
  },
  line: {
    width: '16.95px',
    position: 'relative',
    background: 'linear-gradient(180deg, #A52D2F 0%,  #473099 62%, #35133A 100%)',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '6px',
    paddingRight: '4px',
  },
  title: {
    fontWeight: '700',
    letterSpacing: '-0.41px',
    maxWidth: '75%',
  },
  statusView: {
    marginLeft: '8px',
    border: 'solid 1px #F5CD40',
    borderWidth: '1px',
    paddingLeft: '8.5px',
    paddingRight: '8.5px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '8px',
  },
  status: {
    letterSpacing: '-0.41px',
    fontSize: '8px',
    marginTop: '1px',
    color: '#ffffff',
  },
  description: {
    letterSpacing: '-0.41px',
    fontSize: '12px',
    marginBottom: '8px',
  },
  date: {
    letterSpacing: '-0.41px',
    fontSize: '12px',
  },
};

export const Container = styled.div`
  height: 100%;
  padding-bottom: 50px;
`;

export const RoadMapBoxContainer = styled(Box)<{$color: string}>`
  background: linear-gradient(90deg, #2c283e 0%, #364147 100%);
  border-radius: 16px;
  padding-left: 14px;
  padding-top: 8px;
  width: 100%;
  padding-bottom: 8px;
  padding-right: 4px;
  margin-left: 14px;
  border-left: 3px solid ${({$color}) => $color};
`;
