import {colors} from '../../../../constants';
import {Styles} from '../../../../types';

export const styles: Styles = {
  container: {
    width: 'calc(100% - 20px)',
    display: 'flex',
    flexDirection: 'row',
    background: 'rgba(255, 255, 255, 0.11)',
    borderRadius: '16px',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  iconView: {
    width: '38px',
    height: '38px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '8px',
    background: `linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`,
  },
  infoView: {
    flex: 1,
    marginLeft: '16px',
    alignItems: 'start',
    width: '100%',
  },
  title: {
    color: colors.white,
    fontWeight: '700',
  },
  coinsView: {
    display: 'flex',
    alignItems: 'center',
  },
  coins: {
    fontSize: '12px',
    fontWeight: '700',
    color: colors.white,
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    marginLeft: '4px',
  },
};
