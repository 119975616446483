import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector, useLazyGetRewardsQuery, useUpdateSocialMediaRewardMutation} from '../../hooks';
import {selectUser, updateUserAction} from '../../reducers/user';
import {Box, Typography} from '@mui/material';
import {ModalContentText, ModalContentWrapper, styles} from './styles';
import RowItem from './components/RowItem/RowItem';
import {useNavigate} from 'react-router-dom';
import {fetchAndSetRewardAction, selectReward, setRewardAction} from '../../reducers/reward';
import {BackgroundImgContainer, Loader, UsuallyButton, UsuallyModal} from '../../components';
import { SocialMediaReward, UserRewardData } from '../../types/entities';

const Reward = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [socialMediaReward, setSocialMediaReward] = useState<SocialMediaReward | null>(null);
  const [userRewardData, setUserRewardData] = useState<UserRewardData | null>(null);

  const {data: user} = useAppSelector(selectUser);
  const {currentDay, isGetReward} = useAppSelector(selectReward);
  const { data: dataReward } = useAppSelector(selectReward);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getRewardsQuery, {isLoading}] = useLazyGetRewardsQuery();
  const [updateSocialMediaReward] = useUpdateSocialMediaRewardMutation();

  useEffect(() => {
    getData();
  }, []);

  const handleMidalOpen = () => setIsOpenModal(true);
  const handleModalClose = () => {
    setSocialMediaReward(null);
    setUserRewardData(null);
    setIsOpenModal(false);
  }

  const getData = async () => {
    if (user) {
      try {
        const {data} = await getRewardsQuery({user_tg_id: user.user_tg_id});
        if (data) {
          dispatch(updateUserAction({server_time: data.server_time}));
          dispatch(setRewardAction(data));
          dispatch(fetchAndSetRewardAction());
        }
      } catch (error) {}
    }
  };

  if (!user) {
    return <></>;
  }

  const joinTheSocial = async () => {
    try {
      const data = {
        ...userRewardData,
        reward_received: true
      };

      if (userRewardData && !userRewardData.reward_received) await updateSocialMediaReward(data).unwrap();
      if (socialMediaReward?.url) window.location.href = socialMediaReward?.url;

      handleModalClose();
    } catch (error) {
      console.error(error);
      handleModalClose();
    }
  };

  const handleClickItem = useCallback(
    ({ coins_number, id, title, url }: SocialMediaReward, reward: UserRewardData | undefined) => () => {
      if (!reward || !reward.id) return;
      setUserRewardData(reward);
      setSocialMediaReward({ coins_number, id, title, url });
      handleMidalOpen();
    },
    []
  );

  const handlePressDaily = useCallback(() => {
    navigate('/reward/daily');
  }, []);

  const handlePressInviteFriends = useCallback(() => {
    navigate('/friends');
  }, []);

  return (
    <BackgroundImgContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.container}>
          <Typography sx={styles.title}>Винагороди</Typography>
          <Typography sx={styles.description}>
            Беріть участь у спеціальних активностях та отримуйте винагороди. Щоб отримати винагороду, виконуйте завдання від наших партнерів.
          </Typography>
          <Box>
            <Typography sx={styles.typographyDaily}>Щоденні завдання</Typography>
            <RowItem
              title={'Щоденні винагороди'}
              icon="Calendar"
              coins={isGetReward && currentDay ? currentDay.coins_number : undefined}
              onClick={handlePressDaily}
            />
            <Typography sx={styles.typographyTasks}>Список завдань</Typography>
            <RowItem title={'Запросіть 5 друзів'} icon="Sharing" coins={15000} onClick={handlePressInviteFriends} />

            {dataReward?.social_media_rewards?.social_media_rewards.map(({ coins_number, id, title, url }) => {
              const reward = dataReward?.social_media_rewards?.user_reward_data.find(item => item.social_media_reward === id);

              return <RowItem
                key={id}
                disabled={!reward?.id || reward.reward_received}
                title={`Приєднатися до ${title}`}
                icon="Cursor"
                coins={reward?.reward_received ? 0 : coins_number}
                onClick={handleClickItem({ coins_number, id, title, url }, reward)}
              />
            })}
            {/* <RowItem title={'Отримайте 10 000 монет'} icon="Hoops" disabled coins={2000} onClick={handlePressInActive} /> */}
            <UsuallyModal isVisible={isOpenModal} handleClose={handleModalClose}>
              {socialMediaReward && userRewardData &&
                <ModalContentWrapper>
                  <ModalContentText>{`Приєднатися до ${socialMediaReward.title}`}</ModalContentText>
                  <UsuallyButton onClick={joinTheSocial} title="Дякую!"></UsuallyButton>
                </ModalContentWrapper>
              }
            </UsuallyModal>
          </Box>
        </Box>
      )}
    </BackgroundImgContainer>
  );
};

export default Reward;
