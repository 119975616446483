import React, {useEffect, useRef} from 'react';
import {Box, List, ListItem, Typography} from '@mui/material';
import {Container, RoadMapBoxContainer, styles} from './styles';
import {TGetRoadMapResponse} from '../../../services/api/roadMap';
import {ERoadMapStatus} from '../../../types';
import moment from 'moment';

type TProps = {
  data: TGetRoadMapResponse;
};

const RoadMap = ({data}: TProps) => {
  const listRef = useRef<any>(null);
  const lineRef = useRef<any>(null);

  useEffect(() => {
    const list = listRef.current;
    const line = lineRef.current;

    if (list && line) {
      const listItems = list.querySelectorAll('.MuiListItem-root');
      if (listItems.length > 0) {
        const firstItem = listItems[0];
        const lastItem = listItems[listItems.length - 1];

        const firstItemRect = firstItem.getBoundingClientRect();
        const lastItemRect = lastItem.getBoundingClientRect();
        const lineHeight = lastItemRect.top + lastItemRect.height / 2 - (firstItemRect.top + firstItemRect.height / 2);
        const lineTop = firstItemRect.top + firstItemRect.height / 2 - list.getBoundingClientRect().top;

        line.style.height = `${lineHeight}px`;
        line.style.top = `${lineTop}px`;
      }
    }
  }, [data]);

  const statusColor = (status: ERoadMapStatus) => {
    switch (status) {
      case ERoadMapStatus.READY:
        return {
          status: '#6340F5',
          title: '#FFFFFF',
          description: '#FFFFFF',
          date: '#6340F5',
          circle: '#5137BD',
          circleBackgroundColor: 'linear-gradient(90deg, rgba(63, 37, 164, 0.5) 0%, rgba(60, 14, 61, 0.5) 100%)',
        };
      case ERoadMapStatus['IN PROGRESS']:
        return {
          status: '#F5CD40',
          title: '#BABABA',
          description: '#BABABA',
          date: '#9F9F9F',
          circle: '#858585',
          circleBackgroundColor: 'rgba(75, 75, 75, 0.5)',
        };

      case ERoadMapStatus.PLANNED:
        return {
          status: '#BABABA',
          title: '#BABABA',
          description: '#BABABA',
          date: '#9F9F9F',
          circle: '#858585',
          circleBackgroundColor: 'rgba(75, 75, 75, 0.5)',
        };

      default:
        return {
          status: '#BABABA',
          title: '#BABABA',
          description: '#BABABA',
          date: '#9F9F9F',
          circle: '#858585',
          circleBackgroundColor: 'rgba(75, 75, 75, 0.5)',
        };
    }
  };

  const statusLabel = (status: string): string => {
    const statusMap: {[key: string]: string} = {
      in_progress: 'IN PROGRESS',
      completed: 'READY',
      planned: 'PLANNED',
    };

    return statusMap[status] || 'UNKNOWN';
  };

  return (
    <Container>
      <Typography sx={styles.typography}>
        Ми хочемо поділитися нашими майбутніми оновленнями і сподіваємося, що ви залишитеся з нами надовго.
      </Typography>
      <Box sx={styles.wrapper}>
        <Box ref={lineRef} sx={styles.line} />
        <List sx={{width: '100%'}} ref={listRef}>
          {data.map((el, index) => (
            <ListItem sx={styles.listItem}>
              <Box
                sx={{
                  ...styles.listItemBackgroundCircle,
                  background: statusColor(el.status).circleBackgroundColor,
                }}>
                <Box
                  sx={{
                    ...styles.listItemCircle,
                    background: statusColor(el.status).circle,
                  }}></Box>
              </Box>
              <RoadMapBoxContainer $color={statusColor(el.status).status} key={index}>
                <Box sx={styles.titleBox}>
                  <Typography sx={{...styles.title, color: statusColor(el.status).description}}>{el.title}</Typography>
                  <Box sx={{...styles.statusView, borderColor: statusColor(el.status).status}}>
                    <Typography sx={{...styles.status, color: statusColor(el.status).status}}>
                      {statusLabel(el.status)}
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{...styles.description, color: statusColor(el.status).description}}>
                  {el.description}
                </Typography>
                <Typography sx={{...styles.date, color: statusColor(el.status).date}}>
                  {moment(el.end_date).format('YYYY/MM/DD')}
                </Typography>
              </RoadMapBoxContainer>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default RoadMap;
