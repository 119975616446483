import React from 'react';
import {Typography} from '@mui/material';
import {AmountContainer, Container, styles} from './styles';
import {formattedNumber} from '../../../helpers';
import {ReactComponent as CrystalSVG} from '../../../assets/icons/crystal.svg';

type TProps = {
  coins: number;
};

const TotalBalance = ({coins}: TProps) => {
  return (
    <Container>
      <Typography sx={styles.title}>Загальний баланс</Typography>
      <AmountContainer>
        <CrystalSVG />
        <Typography sx={styles.value}>{formattedNumber(Number(coins.toFixed(0)))}</Typography>
      </AmountContainer>
    </Container>
  );
};

export default TotalBalance;
