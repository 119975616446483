import {motion} from 'framer-motion';
import styled from 'styled-components';

export const FloatingNumber = styled(motion.div)`
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  width: 51px;
  height: 51px;
  background-color: rgba(255, 255, 255, 0.33);
  box-shadow: inset 4px -3px 4px #f1ff60, inset 0px 2px 6px #a4ff80;
  filter: drop-shadow(0px 4px 24px #1d96c6);
  border-radius: 90px;
  pointer-events: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


