import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {BackgroundImgContainer, GradientBunner, UsuallyButton, UsuallyModal} from '../../components';
import {assets} from '../../assets';
import {ButtonContainer, Container, ContentContainer, Description, ItemsContainer, TimerContainer, Title} from './styles';
import Timer from '../../components/dataview/Timer/Timer';
import {
  useAppDispatch,
  useAppSelector,
  useFormattedFightData,
  useGetFightQuery,
  useGetUserFightsDataQuery,
  useLazyUpdateUserQuery,
} from '../../hooks';
import {selectUser, updateUserAction} from '../../reducers/user';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UserFightInfoItem from './components/UserFightInfoItem/UserFightInfoItem';
import {useNavigate} from 'react-router-dom';
import {EFightStatus} from '../../types/entities/fight';
import {ResultContent} from './components';
import {TUser} from '../../types/entities';
import {resetFightAction, selectFight} from '../../reducers/fight';
import { colors } from '../../constants';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

type TProps = {};

const getTimeRemainingInSeconds = (utcTime: string): number => {
  const targetDate = dayjs.utc(utcTime).local();
  const now = dayjs();

  const timeDifferenceInMilliseconds = targetDate.diff(now);

  if (timeDifferenceInMilliseconds <= 0) {
    return 0;
  }
  const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  return timeDifferenceInSeconds;
};

const FightResult: React.FC<TProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const {data: user} = useAppSelector(selectUser);
  const {lastFightId} = useAppSelector(selectFight);

  const [updateUserQuery] = useLazyUpdateUserQuery();
  const {data: activeFightData, refetch} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});
  const {
    data: fightData,
    refetch: refetchUserFightData
  } = useGetUserFightsDataQuery({user_id: user?.id ?? ''}, {skip: !user?.id});

  const [isFinished, setIsFinished] = useState(getTimeRemainingInSeconds(activeFightData?.end_at ?? '') === 0);

  const formattedFightData = useFormattedFightData(activeFightData, user?.id ?? null);

  const onPressHandler = useCallback(async () => {
    if (activeFightData?.status === EFightStatus.FINISH) {
      setModalVisible(true);
    } else if (lastFightId) {
      const response = await refetch().unwrap();
      if (response.status === EFightStatus.FINISH) {
        setModalVisible(true);
      } else {
        navigate('/');
      }
    }
  }, [navigate, activeFightData]);

  const isWin = useMemo(
    () =>
      formattedFightData &&
      formattedFightData.status === EFightStatus.FINISH &&
      (formattedFightData.host.score ?? 0) > (formattedFightData.opponent.score ?? 0),
    [formattedFightData],
  );

  const onPressGetFightResult = useCallback(
    (userData: TUser | null, onUpdateValue: number) => async () => {
      if (userData) {
        const updateCoinValue = Math.floor(userData.coins_num + onUpdateValue);

        const userDataFormat: TUser = Object.assign({}, user, {
          coins_num: updateCoinValue < 0 ? 0 : updateCoinValue,
        });

        const {data} = await updateUserQuery(JSON.stringify(userDataFormat));
        dispatch(updateUserAction(data));
        dispatch(resetFightAction());
        refetchUserFightData()
      }
    },
    [],
  );

  useEffect(() => {
    if (lastFightId) {
      refetch();
    }
  }, [lastFightId]);

  useEffect(() => {
    if (!lastFightId) {
      setModalVisible(false);
      navigate('/fights');
    }
  }, [lastFightId, navigate]);

  return (
    <BackgroundImgContainer gradient={`linear-gradient(34.07deg, #001956 22.09%, #1F91CA 58.06%, #0FADAC 76.41%, #01CF8F 93.38%)`}>
      <Container>
        <Title>Ваш бій вдався!</Title>
        <Title> Щиро вітаю!</Title>
        <Description>
        Дізнайтеся, скільки балів ви заробили
        </Description>
        {formattedFightData && activeFightData ? (
          <ContentContainer>
            <>
              {/* <TimerContainer>
                {isFinished ? (
                  <GradientBunner text="Time out" />
                ) : (
                  <Timer
                    isStart={true}
                    onTimeEnd={() => {
                      setIsFinished(true);
                    }}
                    initialTime={getTimeRemainingInSeconds(activeFightData?.end_at)}
                  />
                )}
              </TimerContainer> */}
              <ItemsContainer>
                <UserFightInfoItem
                  url={assets.userAvatar}
                  fio={`${formattedFightData.host.first_name} ${formattedFightData.host.last_name}`}
                  fightStatus={activeFightData.status}
                  points={formattedFightData.host.score}
                />
                <UserFightInfoItem
                  url={assets.opponentAvatar}
                  fio={`${formattedFightData.opponent.first_name} ${formattedFightData.opponent.last_name}`}
                  fightStatus={activeFightData.status}
                  points={formattedFightData.opponent.score}
                />
              </ItemsContainer>
            </>
            <ButtonContainer>
              <UsuallyButton title="Get it" onClick={onPressHandler} />
            </ButtonContainer>
          </ContentContainer>
        ) : (
          <></>
        )}
        <UsuallyModal isVisible={modalVisible} handleClose={() => setModalVisible(false)}>
          <ResultContent
            onPress={onPressGetFightResult(
              user,
              isWin ? formattedFightData?.bet ?? 0 : -(formattedFightData?.bet ?? 0),
            )}
            title={isWin ? 'Вітаю з перемогою!' : 'На жаль, ви програли(('}
            price={`${isWin ? '+' : '-'}${formattedFightData?.bet ?? 0}`}
          />
        </UsuallyModal>
      </Container>
    </BackgroundImgContainer>
  );
};

export default FightResult;
