import {transform} from 'typescript';
import {Styles} from '../../types';
import styled from 'styled-components';

export const styles: Styles = {
  animatedComponent: {
    position: 'absolute',
    zIndex: 9999,
    left: 'var(--start-pos, 0)',
    top: 'var(--start-pos-y, 0)',
    transform: 'translate(-50%, -50%)',
    animation: 'fadeInUp 1.5s ease forwards',
    '> span': {
      color: 'white',
      fontSize: '20px',
    },
  },
};

export const fadeInUpKeyframes = `
    @keyframes fadeInUp {
      0% {
        top: var(--start-pos-y, 0);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        top: var(--end-pos-y, 0);
        opacity: 0;
      }
    }
  `;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.div`
  /* position: absolute; */
  width: 100%;

  height: auto;
  top: 10px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
`;

export const ImageContainer = styled.div`
  width: 75%;
  background: radial-gradient(circle, rgba(46, 185, 255, 0.7) 0%, rgba(46, 185, 255, 0.7) 0.01%, transparent 60%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
`;
