import styled from 'styled-components';
import {EStatusFightAvatar, TContainerStyle, TImageStyle} from './types';
import {colors} from '../../../constants';

export const Container = styled.div<TContainerStyle>`
  width: ${({$size}) => $size};
`;

export const ImageContainer = styled.div<TContainerStyle>`
  width: ${({$size}) => $size};
  height: ${({$size}) => $size};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: ${({isOpponent, status, $size}) => `linear-gradient(
    180deg,
    ${isOpponent && status === EStatusFightAvatar.READY ? colors.gray_gradient_first_color_E3EAFF : colors.secondary_gradient_first_color_E3FFED} 0%,
    ${isOpponent && status === EStatusFightAvatar.READY ? colors.gray_gradient_second_color_314C86 : colors.secondary_gradient_second_color_36D0EC} 100%
  )`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img<TImageStyle>`
  width: ${({isOpponent, status, $size}) => isOpponent && status !== EStatusFightAvatar.READY ? `66%` : `100%`};
  height: ${({isOpponent, status}) => isOpponent && status !== EStatusFightAvatar.READY ? `66%` : `100%`};
`;

export const StatusContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  height: 24px;
  width: 24px;
`;

export const Title = styled.p`
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
`;
