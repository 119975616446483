import React, {useCallback, useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import {ReactSVG} from 'react-svg';
import {UsuallyButton} from '../..';
import CoinSVG from '../../../assets/icons/coin.svg';
import SilverCoinSVG from '../../../assets/icons/silver-coin.svg';
import {TMainer} from '../../../types/entities';
import {TPromoCode} from '../../../services/api/promoCodes/types';
import {formattedNumberByСards} from '../../../helpers';

type TProps = {
  promo: TPromoCode;
  coins: number;
  onApprove: (promo: TPromoCode) => void;
  onClose: () => void;
};

const PromoModalContant: React.FC<TProps> = ({promo, coins, onApprove, onClose}) => {
  const handleClick = useCallback(() => {
    onApprove(promo);
    onClose();
  }, [promo, onApprove, onClose]);

  const isAdd = useMemo(() => {
    return coins >= promo.price && !promo.code;
  }, [coins, promo]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.infoView}>
        <Typography sx={styles.productTitle}>{promo.name}</Typography>
      </Box>

      <Box sx={styles.profitPerHourInfo}>
        <Box sx={styles.iconContainer}>
          <ReactSVG
            src={CoinSVG}
            beforeInjection={svg => {
              svg.setAttribute('style', 'width: 16px; height: 16px; display: block; margin: auto;');
            }}
          />
        </Box>
        {/* <Typography sx={styles.profitPerHourPrice}>+{profitCount}</Typography> */}
      </Box>
      <Box sx={styles.profitPerHourView}>
        <ReactSVG
          src={CoinSVG}
          beforeInjection={svg => {
            svg.setAttribute('style', 'width: 24px; height: 24px; display: block; margin: auto;');
          }}
        />
        <Typography sx={styles.coins}>{formattedNumberByСards(promo.price)}</Typography>
      </Box>
      <UsuallyButton onClick={handleClick} title={!promo.code ? 'buy promo code' : 'see code'} />
    </Box>
  );
};

export default PromoModalContant;
