import styled from 'styled-components';
import {colors} from '../../constants';

export const Container = styled.div`
  padding: 24px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const Title = styled.p`
  font-size: 24px;
  color: ${colors.white};
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.white};
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
