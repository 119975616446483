import styled from 'styled-components';
import {colors} from '../../../constants';
import {Styles} from '../../../types';
import {Modal} from '@mui/material';

export const styles: Styles = {
  container: {
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  contentView: {
    width: 'calc(100% - 32px)',
    marginBottom: '16px',
    borderRadius: '16px',
    textAlign: 'center',
    paddingTop: '34px',
    paddingBottom: '19px',
    boxShadow: '0px -5px 20px 10px rgba(1, 207, 143, 0.6)',
    backgroundColor: colors.black_1D1D1D,
  },
};
export const ModalContainer = styled(Modal)`
  &:focus-visible {
    outline: 0;
    border: none;
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  &:focus-visible {
    outline: 0;
    border: none;
  }
`;
