import React, {useMemo} from 'react';
import {Amount, AmountContainer, CoinSvgStyle, Container, FightSvgStyle, Title} from './styles';
import {ReactSVG} from 'react-svg';
import {EStatusType} from '../../types';
import CoinSVG from '../../../../assets/icons/coin.svg';
import FightSVG from '../../../../assets/icons/fight.svg';
import EnergySVG from '../../../../assets/icons/energy.svg';

type TProps = {
  amount: number;
  title: string;
  svgType: EStatusType;
};
{
}

const TotalAmount: React.FC<TProps> = ({amount, title, svgType}) => {
  const svgData = useMemo(() => {
    switch (svgType) {
      case EStatusType.COINS:
        return {
          svg: EnergySVG,
          style: CoinSvgStyle,
        };
      default:
        return {
          svg: FightSVG,
          style: FightSvgStyle,
        };
    }
  }, [svgType]);

  return (
    <Container>
      <Title>{title}</Title>
      <AmountContainer>
        <ReactSVG src={svgData.svg} style={svgData.style} />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '24px',
            paddingTop: '4px',
          }}></div>
        <Amount>{amount}</Amount>
      </AmountContainer>
    </Container>
  );
};

export default TotalAmount;
