import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 170px);
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const PromoCodesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding-bottom: 100px;
`;

export const HeaderContainer = styled.div`
  margin-top: 26px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
`;
