import {Box, Typography} from '@mui/material';
import {styles} from './styles';
import {TMainer} from '../../types/entities';
import {useCallback, useMemo} from 'react';
import {Styles} from '../../types';
import {BASE_URL_FOR_ASSETS} from '../../services/api/api';
import {formattedNumberByСards} from '../../helpers';
import {ReactComponent as CrystalSVG} from '../../assets/icons/crystal.svg';

type TProps = {
  item: TMainer;
  coins: number;
  onClick: (item: TMainer) => void;
};

const CardRow = ({item, coins, onClick}: TProps) => {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  const profitCount = useMemo(() => {
    return item.level === 0 ? item.earnings_h : item.earnings_h * 2;
  }, [item]);

  const profitPrice = useMemo(() => {
    return item.level === 0 ? item.price : item.price * 3;
  }, [item]);

  const isDisable = useMemo(() => {
    return coins < profitPrice;
  }, [profitPrice, coins]);

  return (
    <div onClick={handleClick}>
      <Box sx={styles.container}>
        <Box sx={styles.infoView}>
          <Typography sx={styles.title}>{item.name}</Typography>
          <Typography sx={styles.level}>lvl {item.level}</Typography>
          <Box sx={styles.imgView}>
            <img
              srcSet={`${BASE_URL_FOR_ASSETS + item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${BASE_URL_FOR_ASSETS + item.image}?w=164&h=164&fit=crop&auto=format`}
              alt={item.name}
              style={{width: '32px', height: '32px'}}
              loading="lazy"
            />
          </Box>
        </Box>
        <Typography sx={styles.profitPerHour}>Прибуток за годину</Typography>
        <Box sx={styles.profitPerHourInfo}>
          <Box sx={styles.iconContainer}>
            <CrystalSVG />
          </Box>
          <Typography sx={styles.profitPerHourPrice}>+{profitCount}</Typography>
        </Box>
        <Box sx={{...styles.improveLevelView, ...(isDisable && styles.disable)} as Styles}>
          <Typography sx={styles.improveLevelTitle}>Просувати</Typography>
          <Box sx={styles.improveLevelPriceContainer}>
            <CrystalSVG />
            <Typography sx={styles.improveLevelPrice}>{formattedNumberByСards(profitPrice)}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CardRow;
