import {Styles} from '../../../types';
import {colors} from '../../../constants';
import {CSSProperties} from 'react';
import styled from 'styled-components';

export const styles: Styles = {
  title: {
    color: colors.white,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '22px',
  },
  valueContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    fontSize: '20px',
    fontWeight: '700',
    marginLeft: '2px',
    color: colors.white,
  },
};

export const iconStyle: CSSProperties = {
  width: '24px',
  height: '24px',
};

export const Container = styled.div`
  height: 47px;
  padding-left: 16px;
  padding-right: 16px;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
