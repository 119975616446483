import React from "react";
import { ImageContainer, Overlay, Image } from "./styles";


type TProps = {
    src: string | undefined;
    baseWidth: number;
    isVw?: boolean
}


const ImageWithOverlay: React.FC<TProps> = ({src, baseWidth, isVw}) => {
    return (
        <ImageContainer baseWidth={baseWidth} isVw={isVw} >
        <Overlay baseWidth={baseWidth}  isVw={isVw}/>
          <Image src={src} alt="dino" baseWidth={baseWidth}  isVw={isVw} />
        </ImageContainer>
    )
}

export default ImageWithOverlay;