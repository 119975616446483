import styled from 'styled-components';
import {colors} from '../../../../constants';

export const Container = styled.div`
  width: calc(100% - 40px);
  padding-left: 20px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${colors.white};
`;

export const PriceContaienr = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 30px;
  height: 30px;
`;
export const Price = styled.p`
  color: ${colors.white};
  font-weight: 700;
  font-size: 24px;
`;
