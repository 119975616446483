import {CSSProperties} from 'react';
import {Styles} from './types';
import {colors} from './constants';

export const styles: Styles = {
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    zIndex: 999,
    padding: 0,
  },
  bottomNavigationContainer: {
    backgroundColor: colors.white,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    width: '100%',
    display: 'flex',
    position: 'fixed',
    zIndex: 999,
    bottom: 0,
    height: '82px',
  },

  content: {
    paddingBottom: '60px',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: 0,
  },
};

export const linkStyle: CSSProperties = {
  display: 'flex',
  flex: 1,
  textDecoration: 'none',
  justifyContent: 'center',
  alignItems: 'center',
};

export const bottomNavigationStyle: CSSProperties = {
  width: '100%',
  paddingTop: '14px',
  display: 'flex',
  backgroundColor: colors.white,
};
