import React, {useCallback, useEffect, useState} from 'react';
import {Container, HeaderContainer, PromoCodesContainer} from './styles';
import {useAddPromoCodesMutation, useAppDispatch, useAppSelector, useGetPromoCodesQuery} from '../../../hooks';
import {selectUser, setInitialUserData, setUserDataAction, updateUserAction} from '../../../reducers/user';
import CardRow from '../../CardRow/CardRow';
import {TMainer} from '../../../types/entities';
import PromoRow from '../../PromoRow/PromoRow';
import {TAddPromoCodeResponce, TPromoCode} from '../../../services/api/promoCodes/types';
import TotalBalance from '../TotalBalance/TotalBalance';
import UsuallyModal from '../../modals/UsuallyModal/UsuallyModal';
import PromoModalContant from '../PromoModalContent/PromoModalContant';

type TProps = {};

const PromoCodes: React.FC<TProps> = () => {
  const dispatch = useAppDispatch();

  const [selectedPromo, setSelectedPromo] = useState<{
    isVisibleModal: boolean;
    promo?: TPromoCode;
  }>({
    isVisibleModal: false,
    promo: undefined,
  });

  const {data: user, backgroundMining, initialUserData} = useAppSelector(selectUser);

  const {
    data: promoCodes,
    isLoading: isLoadingPromoCodes,
    refetch,
  } = useGetPromoCodesQuery({user_id: user?.id ?? ''}, {skip: !user?.id});

  const [addPromoCode, {isLoading: isLoadingAddPromoCodes, isSuccess}] = useAddPromoCodesMutation();

  const onPressAddPromoCode = useCallback(
    async (promo: TPromoCode) => {
      if (user && initialUserData) {
        const coins_income = user.coins_num - initialUserData.coins_num;
        const data: TAddPromoCodeResponce = await addPromoCode({
          user_id: user?.id ?? '',
          data: {promo_code_id: promo.id, coins_income: parseInt(coins_income.toFixed(0), 10)},
        }).unwrap();
        if (data) {
          dispatch(setInitialUserData({coins_num: data.user.coins_num}));
          dispatch(updateUserAction({coins_num: user.coins_num - promo.price}));
        }
      }
    },
    [user?.id, user?.coins_num, initialUserData],
  );

  const handleClose = useCallback(() => {
    setSelectedPromo({isVisibleModal: false});
  }, []);

  const openSelectPromo = useCallback((item: TPromoCode) => {
    setSelectedPromo({
      isVisibleModal: true,
      promo: item,
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  return (
    <Container>
      <HeaderContainer>
        <TotalBalance coins={user?.coins_num ?? 0} />
      </HeaderContainer>
      <PromoCodesContainer>
        {promoCodes?.map((promo: TPromoCode, index: number) => (
          <PromoRow
            isDisable={(user?.coins_num ?? 0) < promo.price || isLoadingAddPromoCodes}
            item={promo}
            key={promo.id}
            coins={user?.coins_num ?? 0}
            onClick={openSelectPromo}
            isLoading={isLoadingAddPromoCodes}
          />
        ))}
      </PromoCodesContainer>
      <UsuallyModal isVisible={selectedPromo.isVisibleModal} handleClose={handleClose}>
        {selectedPromo.promo && (
          <PromoModalContant
            coins={user?.coins_num || 0}
            onApprove={onPressAddPromoCode}
            promo={selectedPromo.promo}
            onClose={handleClose}
          />
        )}
      </UsuallyModal>
    </Container>
  );
};

export default PromoCodes;
